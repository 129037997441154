import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"

import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import UiUxFaqHeader from "../../components/ui-ux-design/faq/uiuxFaqHeader"
import UiUxFaqContent from "../../components/ui-ux-design/faq/uiuxFaqContent"

import ProjectInMind from "../../components/global/projectInMind"
import DirectContact from "../../components/global/directContact"
import uiuxFaqFeaturedImage from "../../../static/home/wowmakers.jpeg"
import "../../sass/pages/ui-ux-faq.scss"

const uiuxFaqSchema = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What exactly is UX design?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "User experience (UX) design is an umbrella term representing the entire process of creating products and services that provide intuitive and delightful experience to their users.People widely use terms like UI/UX Design or Usability Design to represent User Experience Design. However UI (User Interface) Design and Usability Design are subsets of UX Design.Even though there is User Experience in everything that we use in our everyday lives (like a door, coffee mug or TV remote), off late the term UX Design has been mainly associated with digital/technology products.The objective of user experience designing is not just creating products that are usable but also engaging, efficient and fun to use."
    }
  },{
    "@type": "Question",
    "name": "How good User Experience can help businesses?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "For any business that delivers its service or product through an app or website, UX design is as critical as sales, branding or marketing. Because good UX design directly improves the bottom line.Even if it is a bank with brick and mortar branches all across the country, their app would be the most important channel in which customers interact with the business, making UX a critical factor influencing customer happiness and revenue.It is found that every dollar invested in UX brings $100 in return. This is an average figure, but it’s a ROI of an impressive 9,900%. This stunning return is due to increased conversion rate, increased customer retention, lower customer acquisition cost, lower support costs and improved trust and increased market share."
    }
  },{
    "@type": "Question",
    "name": "What is a typical UX design process?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "When it comes to the UX design process, there is no universal standard as such. Different companies, agencies, designers and product managers follow different approaches, techniques and tools.There are multiple stages in the design process as per the methodology you prefer. Our approach is inspired by the “Double Diamond” model proposed by the British Design Council. Our process include- Discover, Define, Design and Deploy.1. Discover:This is the first stage where we try to understand the needs of the business, stakeholders and its end users. We also evaluate the desired outcomes, KPIs and full scope of the project in this phase. The activities include:Stakeholder interviews, User research and interviews, Business, market and competitor analysis, UX Audit (if revamping existing design) 2. Define: This is where we will analyse all the inputs, feedbacks, observations and arrange them in order to define the problems and needs to be addressed. The Define stage include: Define the User Personas, Define the problems, Red Route analysis for feature prioritisation and User Journey Mapping 3. Design: Design stage starts after the client’s approval of our findings and solutions in the Define phase. Design stage starts with quick sketches to iterate and finalize the solutions. The deliverables includes: Information architecture, Wireframes, User Interface design, Interactive prototypes and Design systems. 4. Deploy: If the client requires it, we have a dedicated team of inhouse software engineers to deploy the designs created by our team. The services included:iOS/Android/Web development, Front-end engineering, Usability testing, Software/bug testing and Final deployment(Project go-live)"
    }
  },{
    "@type": "Question",
    "name": "How much a typical UX design project would cost?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "There are multiple factors influencing the cost of a UX design project. The factors include scope and scale of the project, nature of the project (starting from scratch, revamping existing design), platforms (mobile, web, TV, kiosk), technology involved, and design services included (user research, interviews, testing) and delivery deadline to name a few. It's difficult to provide a number upfront without understanding the requirements and full scope of the project. We usually charge $40 to $50 per hour based on the complexity and size of the project. Our typical UX projects range anywhere between $20,000 to $50,000 and could go well above $100,000 depending on the scope."
    }
  },{
    "@type": "Question",
    "name": "What is the timeline for a UX design project?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "All the factors influencing the cost (scope, complexity, platforms, budget, client feedback) will also affect the delivery timeline of a UX design project. A typical UX design project could go anywhere between 2-3 months to 6 months for a small-medium sized project. Bigger projects could take more than 6 months to 1 year depending on the scope and complexity."
    }
  },{
    "@type": "Question",
    "name": "What are the UX design deliverables?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The deliverables could change based on the requirement and nature of the project. Typically we deliver: User-research report, UX audit report (if revamping an existing design), Red route analysis, User persona, Customer journey maps, Sitemap, Wireframes, UI sketches, Interactive prototypes and Design systems"
    }
  },{
    "@type": "Question",
    "name": "What are the inputs and data the client needs to provide?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Usually we kick start every project with a detailed brainstorming session with all the stakeholders from the client side. These are the details/data we require before the start of every project.Business/organisational goals, Target audience details, Product goals, Expected outcomes and KPIs of the project, Technical specifications, Market research data, if any, Previous user experience audit results if any, User analytics data, if any and Business reports"
    }
  },{
    "@type": "Question",
    "name": "Does WowMakers also undertake software development/programming of the apps/websites?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes we do! We have a super-small and super-talented team of software engineers in house. Our engineers are experienced in iOS, Android and Web development. We also work with software/engineering-focused companies as their UX partners. However, we don’t usually offer software development as a standalone service."
    }
  },{
    "@type": "Question",
    "name": "Do you provide support to the application after the product launch?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We shall extend our support according to the client’s requirements. After ‘User Acceptance Testing (UAT), we shall be providing support for the next 3 months. If the client has opted for an Annual Support Contract (ASC) with us, we are entitled to provide our services at any time during the period of contract."
    }
  },{
    "@type": "Question",
    "name": "What are the tools you use for UX design?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "There are a multitude of tools designers use in various stages of UX design. Our team typically use the following: User Research - Hotjar, Usabilla, Design - Adobe XD, Figma, Balsamiq, Invision, User Testing - Crazyegg, UserTesting.com, Project Management - Basecamp, Miro, Notion and Asset Creation - Zeplin"
    }
  }]
}

export const Head = () => (
  <>
    <SeoHead
      ogImage={uiuxFaqFeaturedImage}
      title="FAQ - UI/UX Design | WowMakers"
      description="Check out the Frequently asked questions (FAQ) on UI and UX design"
    />
      <script type="application/ld+json">
        {JSON.stringify(uiuxFaqSchema)}
      </script>    
  </> 
)

const UiUxFaq = () => {
  return (
    <Layout>
      <Seo bclass="ui-ux-faq" bid="ui-ux-faq"></Seo>
      <ScrollIndicationHeightZero />
      <UiUxFaqHeader />
      <UiUxFaqContent />
      <ProjectInMind title="Do you have a UI & UX project in mind?" />
      <DirectContact />
    </Layout>
  )
}

export default UiUxFaq
