import React from "react"
import { Container, Row, Col } from "react-bootstrap"

const UiUxFaqHeader = () => {
  return (
    <div className="main-secondary text-center">
      <Container>
        <Row className="justify-content-center">
          <Col md={8}>
            <h1 className="main-sec-title">User Experience Design FAQ</h1>
            <p className="main-sec-desc">
              Here we have answered the 10 frequently asked questions about
              UI/UX design. Browse through the questions and in case you don't
              find the answer you are looking for, please feel free to reach out
              to us. We are always happy to help!
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default UiUxFaqHeader
