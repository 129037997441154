import React from "react"
import { Container, Row, Col } from "react-bootstrap"

const UiUxFaqContent = () => {
  return (
    <section className="faq-container">
      <Container>
        <Row>
          <Col lg={6}>
            <div className="faq-display">
              <h2>1. What exactly is UX design?</h2>
              <p>
                User experience (UX) design is an umbrella term representing the
                entire process of creating products and services that provide
                intuitive and delightful experience to their users.
              </p>
              <p>
                People widely use terms like “UI/UX Design” or “Usability
                Design” to represent User Experience Design. However UI (User
                Interface) Design and Usability Design are subsets of UX Design.
              </p>
              <p>
                Even though there is User Experience in everything that we use
                in our everyday lives (like a door, coffee mug or TV remote),
                off late the term UX Design has been mainly associated with
                digital/technology products.
              </p>
              <p>
                The objective of user experience designing is not just creating
                products that are usable but also engaging, efficient and fun to
                use.
              </p>
            </div>
            <div className="faq-display d-lg-none d-xl-none">
              <h2>2. How good User Experience can help businesses?</h2>
              <p>
                For any business that delivers its service or product through an
                app or website, UX design is as critical as sales, branding or
                marketing. Because good UX design directly improves the bottom
                line.
              </p>
              <p>
                Even if it is a bank with brick and mortar branches all across
                the country, their app would be the most important channel in
                which customers interact with the business, making UX a critical
                factor influencing customer happiness and revenue.
              </p>
              <p>
                It is found that “every dollar invested in UX brings $100 in
                return”. This is an average figure, but it’s a ROI of an
                impressive 9,900%. This stunning return is due to increased
                conversion rate, increased customer retention, lower customer
                acquisition cost, lower support costs and improved trust and
                increased market share.
              </p>
            </div>
            <div className="faq-display">
              <h2>3. What is a typical UX design process? </h2>
              <p>
                When it comes to the UX design process, there is no universal
                standard as such. Different companies, agencies, designers and
                product managers follow different approaches, techniques and
                tools.
              </p>
              <p>
                There are multiple stages in the design process as per the
                methodology you prefer. Our approach is inspired by the “Double
                Diamond” model proposed by the British Design Council. Our
                process include- Discover, Define, Design and Deploy.
              </p>
              <div className="sub-list">
                <h3>1. Discover:</h3>
                <p>
                  This is the first stage where we try to understand the needs
                  of the business, stakeholders and its end users. We also
                  evaluate the desired outcomes, KPIs and full scope of the
                  project in this phase. The activities include:{" "}
                </p>
                <ul>
                  <li>Stakeholder interviews</li>
                  <li>User research and interviews</li>
                  <li>Business, market and competitor analysis</li>
                  <li>UX Audit (if revamping existing design)</li>
                </ul>
              </div>
              <div className="sub-list">
                <h3>2. Define:</h3>
                <p>
                  This is where we will analyse all the inputs, feedbacks,
                  observations and arrange them in order to define the problems
                  and needs to be addressed. The Define stage include:
                </p>
                <ul>
                  <li>Define the User Personas</li>
                  <li>Define the problems</li>
                  <li>Red Route analysis for feature prioritisation</li>
                  <li>User Journey Mapping</li>
                </ul>
              </div>
              <div className="sub-list">
                <h3>3. Design:</h3>
                <p>
                  Design stage starts after the client’s approval of our
                  findings and solutions in the Define phase. Design stage
                  starts with quick sketches to iterate and finalize the
                  solutions. The deliverables includes:
                </p>
                <ul>
                  <li>Information architecture</li>
                  <li>Wireframes</li>
                  <li>User Interface design</li>
                  <li>Interactive prototypes</li>
                  <li>Design systems</li>
                </ul>
              </div>
              <div className="sub-list">
                <h3>3. Deploy:</h3>
                <p>
                  If the client requires it, we have a dedicated team of inhouse
                  software engineers to deploy the designs created by our team.
                  The services included:
                </p>
                <ul>
                  <li>iOS/Android/Web development</li>
                  <li>Front-end engineering</li>
                  <li>Usability testing</li>
                  <li>Software/bug testing</li>
                  <li>Final deployment (Project go-live)</li>
                </ul>
              </div>
            </div>
            <div className="faq-display d-lg-none d-xl-none">
              <h2>4. How much a typical UX design project would cost?</h2>
              <p>
                There are multiple factors influencing the cost of a UX design
                project. The factors include scope and scale of the project,
                nature of the project (starting from scratch, revamping existing
                design), platforms (mobile, web, TV, kiosk), technology
                involved, and design services included (user research,
                interviews, testing) and delivery deadline to name a few.
              </p>
              <p>
                It's difficult to provide a number upfront without understanding
                the requirements and full scope of the project. We usually
                charge $40 to $50 per hour based on the complexity and size of
                the project. Our typical UX projects range anywhere between
                $20,000 to $50,000 and could go well above $100,000 depending on
                the scope.
              </p>
            </div>
            <div className="faq-display">
              <h2>5. What is the timeline for a UX design project? </h2>
              <p>
                All the factors influencing the cost (scope, complexity,
                platforms, budget, client feedback) will also affect the
                delivery timeline of a UX design project. A typical UX design
                project could go anywhere between 2-3 months to 6 months for a
                small-medium sized project. Bigger projects could take more than
                6 months to 1 year depending on the scope and complexity.
              </p>
            </div>
            <div className="faq-display d-lg-none d-xl-none">
              <h2>
                <a href="https://www.wowmakers.com/blog/ux-design-deliverables/">
                  6. What are the UX design deliverables?
                </a>
              </h2>
              <p>
                The deliverables could change based on the requirement and
                nature of the project. Typically we deliver:
              </p>
              <ul>
                <li>User-research report</li>
                <li>UX audit report (if revamping an existing design)</li>
                <li>Red route analysis</li>
                <li>User persona</li>
                <li>Customer journey maps</li>
                <li>Sitemap</li>
                <li>Wireframes</li>
                <li>UI sketches</li>
                <li>Interactive prototypes</li>
                <li>Design systems</li>
              </ul>
            </div>
            <div className="faq-display">
              <h2>
                7. What are the inputs and data the client needs to provide?
              </h2>
              <p>
                Usually we kick start every project with a detailed
                brainstorming session with all the stakeholders from the client
                side. These are the details/data we require before the start of
                every project.
              </p>
              <ul>
                <li>Business/organisational goals</li>
                <li>Target audience details</li>
                <li>Product goals</li>
                <li>Expected outcomes and KPIs of the project</li>
                <li>Technical specifications</li>
                <li>Market research data, if any</li>
                <li>Previous user experience audit results if any</li>
                <li>User analytics data, if any</li>
                <li>Business reports</li>
              </ul>
            </div>
            <div className="faq-display d-lg-none d-xl-none">
              <h2>
                8. Does WowMakers also undertake software
                development/programming of the apps/websites?
              </h2>
              <p>
                Yes we do! We have a super-small and super-talented team of
                software engineers in house. Our engineers are experienced in
                iOS, Android and Web development.
              </p>
              <p>
                We also work with software/engineering-focused companies as
                their UX partners. However, we don’t usually offer software
                development as a standalone service.
              </p>
            </div>
            <div className="faq-display">
              <h2>
                9. Do you provide support to the application after the product
                launch?
              </h2>
              <p>
                We shall extend our support according to the client’s
                requirements. After ‘User Acceptance Testing (UAT), we shall be
                providing support for the next 3 months.
              </p>
              <p>
                If the client has opted for an Annual Support Contract (ASC)
                with us, we are entitled to provide our services at any time
                during the period of contract.
              </p>
            </div>
            <div className="faq-display d-lg-none d-xl-none">
              <h2>10. What are the tools you use for UX design?</h2>
              <p>
                There are a multitude of tools designers use in various stages
                of UX design. Our team typically use the following:
              </p>
              <p>
                <span>User Research </span>- Hotjar, Usabilla
              </p>
              <p>
                <span>Design</span> - Adobe XD, Figma, Balsamiq, Invision
              </p>
              <p>
                <span>User Testing</span> - Crazyegg, UserTesting.com
              </p>
              <p>
                <span>Project Management</span> - Basecamp, Miro, Notion
              </p>
              <p>
                <span>Asset Creation</span> - Zeplin
              </p>
            </div>
          </Col>
          <Col lg={6} className="d-none d-lg-block d-xl-block">
            <div className="faq-display">
              <h2>2. How good User Experience can help businesses?</h2>
              <p>
                For any business that delivers its service or product through an
                app or website, UX design is as critical as sales, branding or
                marketing. Because good UX design directly improves the bottom
                line.
              </p>
              <p>
                Even if it is a bank with brick and mortar branches all across
                the country, their app would be the most important channel in
                which customers interact with the business, making UX a critical
                factor influencing customer happiness and revenue.
              </p>
              <p>
                It is found that “every dollar invested in UX brings $100 in
                return”. This is an average figure, but it’s a ROI of an
                impressive 9,900%. This stunning return is due to increased
                conversion rate, increased customer retention, lower customer
                acquisition cost, lower support costs and improved trust and
                increased market share.
              </p>
            </div>
            <div className="faq-display">
              <h2>4. How much a typical UX design project would cost?</h2>
              <p>
                There are multiple factors influencing the cost of a UX design
                project. The factors include scope and scale of the project,
                nature of the project (starting from scratch, revamping existing
                design), platforms (mobile, web, TV, kiosk), technology
                involved, and design services included (user research,
                interviews, testing) and delivery deadline to name a few.
              </p>
              <p>
                It's difficult to provide a number upfront without understanding
                the requirements and full scope of the project. We usually
                charge $40 to $50 per hour based on the complexity and size of
                the project. Our typical UX projects range anywhere between
                $20,000 to $50,000 and could go well above $100,000 depending on
                the scope.
              </p>
            </div>
            <div className="faq-display">
              <h2>
                <a href="https://www.wowmakers.com/blog/ux-design-deliverables/">
                  6. What are the UX design deliverables?
                </a>
              </h2>
              <p>
                The deliverables could change based on the requirement and
                nature of the project. Typically we deliver:
              </p>
              <ul>
                <li>User-research report</li>
                <li>UX audit report (if revamping an existing design)</li>
                <li>Red route analysis</li>
                <li>User persona</li>
                <li>Customer journey maps</li>
                <li>Sitemap</li>
                <li>Wireframes</li>
                <li>UI sketches</li>
                <li>Interactive prototypes</li>
                <li>Design systems</li>
              </ul>
            </div>
            <div className="faq-display">
              <h2>
                8. Does WowMakers also undertake software
                development/programming of the apps/websites?
              </h2>
              <p>
                Yes we do! We have a super-small and super-talented team of
                software engineers in house. Our engineers are experienced in
                iOS, Android and Web development.
              </p>
              <p>
                We also work with software/engineering-focused companies as
                their UX partners. However, we don’t usually offer software
                development as a standalone service.
              </p>
            </div>
            <div className="faq-display">
              <h2>10. What are the tools you use for UX design?</h2>
              <p>
                There are a multitude of tools designers use in various stages
                of UX design. Our team typically use the following:
              </p>
              <p>
                <span>User Research </span>- Hotjar, Usabilla
              </p>
              <p>
                <span>Design</span> - Adobe XD, Figma, Balsamiq, Invision
              </p>
              <p>
                <span>User Testing</span> - Crazyegg, UserTesting.com
              </p>
              <p>
                <span>Project Management</span> - Basecamp, Miro, Notion
              </p>
              <p>
                <span>Asset Creation</span> - Zeplin
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default UiUxFaqContent
